/* Remove default Accordian styles */
details[data-popover] > summary:focus {
    outline: none;
  }
  details[data-popover] > summary::-webkit-details-marker {
    display: none;
  }
  details[data-popover] > summary {
    list-style: none;
  }
  /* End removing default accordian styles */

  details[data-popover] > summary {
    list-style: none;
  }


  details[data-popover] {
    position: relative;
    z-index: 10;
  }
  details[data-popover] > summary + * {
    position: absolute;
    background-color: #333333;
    color: rgb(144, 238, 233);
    padding: 1rem;
    border-style: solid;
    border-radius: 0.5rem;
    border-color:  rgb(144, 238, 233);
  }

  /* Position */
  details[data-popover="down"] > summary + * {
    bottom: -1*calc(0.5rem + 100%);
    left: -375%;
    transform: translateX(100%);
  }