@font-face {
  font-family: 'SEGA LOGO FONT';
  font-style: normal;
  font-weight: normal;
  src: local('SEGA LOGO FONT'), url('SEGA.woff') format('woff');
}

h1 {
  font-family: 'SEGA LOGO FONT';
  color: rgb(144, 238, 233);
}


#layout__main__left::-webkit-scrollbar {
  display: none;
}