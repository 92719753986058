#PhoneList {
    text-align: left;
}

ul, #poly {
    list-style-type:none;
    margin-left: 0;
    padding:0;
  padding-inline-start:20px;
  margin-block-end: 10px;
  margin-block-start: 10px;
}

#poly, #yealink, #online {
    margin:0.5em;
    padding:0;
    color:rgb(144, 238, 233);
}

#offline {
    color:rgb(154, 160, 160);
}

li {
    white-space: nowrap;
    text-decoration: none;
    color:rgb(144, 238, 233);
    cursor:pointer;
}

ul>li>ul>li:hover, label:hover {
    background: #828682;
}


label {
    display: block;
}


/* input[type="checkbox"]{
    position: absolute;
    left: -9999px;
  } */

  /* input[type="checkbox"] ~ ul{

    height: 100%;
    transform-origin: top;
    transition: transform .2s ease-in-out;
    transform: scaleY(1); 
  }

  input[type="checkbox"]:checked ~ ul{
    height: 0;
    transform: scaleY(0);
  }

.caret {
    display:block;
    transition: all 0.25s ease-out;
}
.caret::before {
    content: "\25BC";
    color:rgb(144, 238, 233);
    display: inline-block;
    margin-right: 6px;  
    transition: transform .2s ease-out;
}

input[type="checkbox"]:checked + .caret::before {
    transform: rotate(-90deg);
} */