#SiteList {
    text-align: left;
}

ul, #sj, #ho {
    list-style-type:none;

}

#sj, #ho {
    margin:0.3em;
    padding:0;
    color:rgb(144, 238, 233);

}

li {
    display: block;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    text-decoration: none;
    color:rgb(144, 238, 233);
    cursor:pointer;
}
/* 
ul>li>ul>li:hover, label:hover {
    background: #828682;
} */


label {
    display: block;
}

input[type="checkbox"]{
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] ~ ul{

    height: 100%;
    transform-origin: top;
    transition: transform .2s ease-in-out;
    transform: scaleY(1); 
  }

  input[type="checkbox"]:checked ~ ul{
    height: 0;
    transform: scaleY(0);
  }

